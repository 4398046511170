import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SSRProvider from "react-bootstrap/SSRProvider";

import DefaultHeader from "../../components/default-header";
import Footer from "../../components/footer";
import GdprPanel from "../../components/gdpr";
import Navigation from "../../components/navigation";
import Seo from "../../components/seo";
import NewsletterSignup from "../../components/newsletterSignUp";

const Page = ({ data }) => {
  return (
    <>
      <SSRProvider>
        <Navigation />
        <DefaultHeader
          headline="Terms of Service"
          heroBackground="url('https://web-cdn.meridianuniversity.edu/header-images/meridian-basic-forest-header-image-2023.webp')"
          heroMobileBackground="url('https://web-cdn.meridianuniversity.edu/header-images/meridian-basic-forest-header-image-mobile-2023.webp')"
        />
        <div id="body">
          <Container fluid="lg">
            <Row>
              <Col lg={12}>
                <Row className="white-bg mb-5">
                  <Col className="p-4">
                    <MDXProvider>
                      <MDXRenderer>{data.mdx.body}</MDXRenderer>
                    </MDXProvider>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
        <GdprPanel />
        <NewsletterSignup />
      </SSRProvider>
    </>
  );
};

export const Head = () => (
  <Seo
    title="Terms of Service | Meridian University"
    description="Meridian University Terms and Conditions"
    keywords="Terms and Condition, Terms, Conditions"
    pathname="/terms-of-service"
  />
);

export const query = graphql`
  query TofSPageQuery {
    mdx(frontmatter: {tos: {eq: true}}) {
      body
    }
  }
`;

export default Page;
